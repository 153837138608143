import { useRef, Children } from "react";
import PropTypes from "prop-types";
import { ArrowLeft } from "./icons/arrowLeft";
import { ArrowRight } from "./icons/arrowRight";
import { usePosition } from "./hooks/usePosition";
import styles from "./carousel.module.css";

// Inspired by AKA stolen from https://css-tricks.com/a-super-flexible-css-carousel-enhanced-with-javascript-navigation/
export function Carousel({ children }) {
  const ref = useRef();
  const { hasItemsOnLeft, hasItemsOnRight, scrollRight, scrollLeft } = usePosition(ref, children);
  const leftArrowStyles = `${styles.arrow} ${styles.arrowLeft} ${
    hasItemsOnLeft ? styles.visible : styles.notVisible
  }`;
  const rightArrowStyles = `${styles.arrow} ${styles.arrowRight} ${
    hasItemsOnRight ? styles.visible : styles.notVisible
  }`;
  return (
    <div className={styles.container} role="region" aria-label="Products carousel">
      <div className={styles.innerContainer} ref={ref}>
        <div className={styles.spacer}></div>
        {Children.map(children, (child, index) => (
          <div className={styles.cell} key={index}>
            {child}
          </div>
        ))}
        <div className={styles.spacer}></div>
      </div>
      <button className={leftArrowStyles} onClick={scrollLeft} aria-label="Previous product">
        <ArrowLeft />
      </button>
      <button className={rightArrowStyles} onClick={scrollRight} aria-label="Next product">
        <ArrowRight />
      </button>
    </div>
  );
}

Carousel.propTypes = { children: PropTypes.node.isRequired };
