import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { SplitTwoImage } from "./Hero";
import { useMobileDetect } from "@hooks/index";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";

function Hero2(props) {
  const { imgLeft, imgRight, mobileImgLeft, mobileImgRight, title, subTitle, ctas, priority } =
    props;
  const [currentLeftImg, setLeftImg] = useState(imgLeft);
  const [currentRightImg, setRightImg] = useState(imgRight);
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  const links = ctas ? ctas.map((cta) => getLink(cta)) : [{}, {}];

  useEffect(() => {
    if (isMobile) {
      if (mobileImgLeft) {
        setLeftImg(mobileImgLeft);
      }
      if (mobileImgRight) {
        setRightImg(mobileImgRight);
      }
    } else {
      setLeftImg(imgLeft);
      setRightImg(imgRight);
    }
  }, [isMobile]);

  return (
    <SplitTwoImage
      imgLeft={getSanityNextImage(
        currentLeftImg,
        { small: "800", medium: "800", large: "1200" },
        currentLeftImg.altText,
        "cover",
        priority
      )}
      imgRight={getSanityNextImage(
        currentRightImg,
        { small: "800", medium: "800", large: "1200" },
        currentRightImg.altText,
        "cover",
        priority
      )}
      title={title}
      subTitle={subTitle}
      linkLeft={links[0]}
      linkRight={links[1]}
    />
  );
}

Hero2.propTypes = {
  imgLeft: PropTypes.object,
  imgRight: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  ctas: PropTypes.arrayOf(PropTypes.object),
};

export default Hero2;
