import styles from "@components/Typography/typography.module.css";
import { blockText } from "@lib/formatString";

const createMarkup = (code) => {
  return { __html: code };
};

export const BlockRenderer = (props) => {
  const { style = "normal" } = props.node;
  const { children } = props;
  const formattedText = children;

  switch (style) {
    case "h1":
      return <h1 className={styles.heading1}>{formattedText}</h1>;
    case "h1-center":
      return (
        <h1 className={styles.heading1}>
          <span className={styles.center}>{formattedText}</span>
        </h1>
      );
    case "h2":
      return <h2 className={styles.heading2}>{formattedText}</h2>;
    case "h2-center":
      return (
        <h2 className={styles.heading2}>
          <span className={styles.center}>{formattedText}</span>
        </h2>
      );
    case "h3":
      return <h3 className={styles.heading3}>{formattedText}</h3>;
    case "h3-center":
      return (
        <h3 className={styles.heading3}>
          <span className={styles.center}>{formattedText}</span>
        </h3>
      );
    case "h4":
      return <h4 className={styles.heading4}>{formattedText}</h4>;
    case "h4-center":
      return (
        <h4 className={styles.heading4}>
          <span className={styles.center}>{formattedText}</span>
        </h4>
      );
    case "normal":
      return <p className={styles.paragraph}>{formattedText}</p>;
    case "normal-center":
      const paragraphStyles = `
        ${styles.paragraph}
        ${styles.textCenter}
        ${styles.marginCenter}
      `;
      return <p className={paragraphStyles}>{formattedText}</p>;
    case "blockquote":
      return <blockquote className={styles.quote}>{blockText(formattedText)}</blockquote>;
    case "blockquote-center":
      const quoteStyles = `
        ${styles.quote}
        ${styles.textCenter}
        ${styles.quoteCenter}
      `;
      return <blockquote className={quoteStyles}>{blockText(formattedText)}</blockquote>;
    case "code":
      return <div dangerouslySetInnerHTML={createMarkup(formattedText)} />;
    case "cta":
      return <div className={styles.callToAction}>{children}</div>;
    default:
      return <p className={styles.paragraph}>{formattedText}</p>;
  }
};
