import PropTypes from "prop-types";
import Cta from "../../Cta";
import { formatted } from "./lib/format";
import styles from "./styles/callout.module.css";
import typographyStyles from "@components/Typography/typography.module.css";

export function Callout(props) {
  const { heading, subHeading, ctas, colorList } = props;
  const wrapperStyles = `${styles.wrapper} ${colorList ? styles.withBackground : ""}`;
  let headingStyles = "";
  if (ctas === undefined || subHeading !== undefined) {
    headingStyles = styles.heading;
  }
  if (ctas !== undefined && subHeading === undefined) {
    headingStyles = `${headingStyles} ${styles.noSubheading}`;
  }

  let subHeadingStyles = `${heading !== undefined ? styles.subHeading : styles.noHeading}`;
  if (ctas === undefined && heading === undefined) {
    subHeadingStyles = `${subHeadingStyles} ${styles.noChildren}`;
  }

  let ctaStyles = "";
  if (heading !== undefined || subHeading !== undefined) {
    ctaStyles = styles.cta;
  }
  if (heading === undefined && subHeading === undefined) {
    ctaStyles = `${ctaStyles} ${styles.ctaAlone}`;
  }
  return (
    <div className={wrapperStyles}>
      {heading && (
        <div className={headingStyles}>
          <h4 className={typographyStyles.heading4}>{formatted(heading)}</h4>
        </div>
      )}
      {subHeading && (
        <div className={subHeadingStyles}>
          <p className={typographyStyles.paragraph}>{formatted(subHeading)}</p>
        </div>
      )}
      {ctas && (
        <div className={ctaStyles}>
          <div className={styles.links}>
            {ctas.map((cta) => (
              <Cta navLink={cta} key={cta.key} underline />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

Callout.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  colorList: PropTypes.string,
  ctas: PropTypes.arrayOf(PropTypes.object),
};
