import PropTypes from "prop-types";
import { Card } from "@components/Editorials/Card";
import styles from "./styles/editorialList.module.css";

export function EditorialList({ title, editorials }) {
  return (
    <section>
      <header className={styles.header}>
        <h2>{title}</h2>
      </header>
      <div className={styles.container}>
        {editorials.map((editorial) => {
          return <Card key={editorial.key} editorial={editorial} />;
        })}
      </div>
    </section>
  );
}

EditorialList.propTypes = { title: PropTypes.string, editorials: PropTypes.array };
