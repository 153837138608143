import PropTypes from "prop-types";
import Cta from "@components/Cta";
import styles from "./styles/navigation.module.css";

export function NavigationGroup(props) {
  const { navigationLinks, title, collectionTitle, description } = props;

  if (!navigationLinks || navigationLinks.length === 0) {
    return null;
  }

  return (
    <>
      <section className={styles.container}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.linksWrapper}>
          {navigationLinks.map((cta) => {
            return <Cta navLink={cta} key={cta.key} underline />;
          })}
        </div>
      </section>
      {description && (
        <section className={styles.description}>
          <h4>{collectionTitle}</h4>
          <p>{description}</p>
        </section>
      )}
    </>
  );
}

NavigationGroup.propTypes = {
  navigationLinks: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  collectionTitle: PropTypes.string,
  description: PropTypes.string,
};
