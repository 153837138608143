import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useInView } from "react-intersection-observer";
import { blockText } from "@lib/formatString";
import { SubscribeForm } from "../Modal/SubscribeForm";
import { trackEvent } from "@lib/track-event";
import styles from "./styles/capture.module.css";

const EmailCapture = (props) => {
  const [id, setProfileID] = useState(null);
  const [thankYouMessage, setthankYouMessage] = useState(false);
  const [showSMSForm, setShowSMSForm] = useState(false);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnc: true,
  });

  const thankYouMessageStyles = `${styles.thankyou}${thankYouMessage ? styles.show : null}`;

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("utm_profileid");
    if (id) {
      setProfileID(id);
    }
  }, []);

  useEffect(() => {
    if (inView) {
      let source = "form";
      if (props.formSource) {
        source = props.formSource
          .toLowerCase()
          .replace(/[^\w\u00C0-\u024f]+/g, "_")
          .replace(/^-+|-+$/g, "");
      }
      if (!Cookies.get(`form_viewed_${source}`)) {
        trackEvent("view_email_capture", { source });
        Cookies.set(`form_viewed_${source}`, "true", { expires: 1 });
      }
    }
  }, [inView]);

  useEffect(() => {
    if (thankYouMessage) {
      const timer = setTimeout(() => {
        setthankYouMessage(false); // count is 0 here
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [thankYouMessage]);

  function toggleForm() {
    setShowSMSForm(!showSMSForm);
  }

  return (
    <section className={styles.container} ref={ref}>
      <h1 className={styles.heading}>{blockText(props.heading)}</h1>
      <article className={styles.form}>
        <SubscribeForm
          id={props._key}
          listID={props.klaviyoList || undefined}
          formSource={props.formSource ? props.formSource : `Form`}
          buttonLabel={props.buttonLabel ? props.buttonLabel : `Sign Up`}
          setthankYouMessage={setthankYouMessage}
          isSMS={showSMSForm}
          showToggle={props.isSMS}
          toggleForm={toggleForm}
          profileID={id}
          disclaimer={props.disclaimer}
        />

        {/* {!thankYouMessage && props.isSMS && (
          <div className={styles.disclaimer}>
            {props.disclaimer} View our <Link href="/legal/privacy-policy">Privacy Policy</Link> and{" "}
            <Link href="/legal/terms-of-service">Terms of service.</Link>
          </div>
        )} */}

        {thankYouMessage && <p className={thankYouMessageStyles}>Thank you for Signing Up</p>}
      </article>
    </section>
  );
};

EmailCapture.propTypes = {};

export default EmailCapture;
