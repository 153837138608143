import dynamic from "next/dynamic";

const Hero1 = dynamic(() => import("../Hero1"));
const Hero2 = dynamic(() => import("../Hero2"));
const Hero3 = dynamic(() => import("../Hero3"));
const Hero4 = dynamic(() => import("../Hero4"));
const Hero5 = dynamic(() => import("../Hero5"));
const Hero6 = dynamic(() => import("../Hero6"));
const Hero7 = dynamic(() => import("../Hero7"));
const Hero8 = dynamic(() => import("../Hero8"));
const CalloutSection = dynamic(() => import("../Callout/index").then((mod) => mod.Callout));
const CollectionSection = dynamic(() =>
  import("../CollectionTiles/index").then((mod) => mod.CollectionTiles)
);
const ProductList = dynamic(() => import("../ProductList"));
const SpreadSection = dynamic(() => import("../Spread"));
const TextSection = dynamic(() => import("../TextSection"));
const NavigationGroup = dynamic(() =>
  import("../NavigationGroup/index").then((mod) => mod.NavigationGroup)
);
const Stores = dynamic(() => import("../Stores"));
const EmailCapture = dynamic(() => import("../EmailCapture"));
const EditorialList = dynamic(() => import("../EditorialList").then((mod) => mod.EditorialList));

const PageBuilder = dynamic(() => import("../PageBuilder"));

const upperFirst = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : "");

export function resolveSections(section) {
  // eslint-disable-next-line import/namespace
  let Section = null;
  switch (upperFirst(section._type)) {
    case "Hero1":
      Section = Hero1;
      break;
    case "Hero2":
      Section = Hero2;
      break;
    case "Hero3":
      Section = Hero3;
      break;
    case "Hero4":
      Section = Hero4;
      break;
    case "Hero5":
      Section = Hero5;
      break;
    case "Hero6":
      Section = Hero6;
      break;
    case "Hero7":
      Section = Hero7;
      break;
    case "Hero8":
      Section = Hero8;
      break;
    case "CalloutSection":
      Section = CalloutSection;
      break;
    case "CollectionSection":
      Section = CollectionSection;
      break;
    case "ProductList":
      Section = ProductList;
      break;
    case "SpreadSection":
      Section = SpreadSection;
      break;
    case "TextSection":
      Section = TextSection;
      break;
    case "NavigationGroup":
      Section = NavigationGroup;
      break;
    case "Stores":
      Section = Stores;
      break;
    case "EmailCapture":
      Section = EmailCapture;
      break;
    case "EditorialList":
      Section = EditorialList;
      break;
    case "PageBuilder":
      Section = PageBuilder;
      break;
    default:
      Section = null;
      break;
  }

  if (Section) {
    return Section;
  }

  return null;
}
