import PropTypes from "prop-types";
import { FathersDay } from "./Hero";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";

function Hero3(props) {
  const { imgLeft, imgRight, title, cta, priority } = props;

  const link = cta ? getLink(cta) : {};

  return (
    <FathersDay
      imgLeft={getSanityNextImage(
        imgLeft,
        { small: "800", medium: "800", large: "1200" },
        imgLeft.altText,
        "cover",
        priority
      )}
      imgRight={getSanityNextImage(
        imgRight,
        { small: "400", medium: "400", large: "600" },
        imgRight.altText,
        "cover",
        priority
      )}
      title={title}
      link={link}
    />
  );
}

Hero3.propTypes = {
  imgLeft: PropTypes.object,
  imgRight: PropTypes.object,
  title: PropTypes.string,
  cta: PropTypes.object,
};

export default Hero3;
