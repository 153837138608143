import PropTypes from "prop-types";
import { SplitThreeImage } from "./Hero";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";

function Hero5(props) {
  const { imgLeft, imgCenter, imgRight, title, cta, priority } = props;

  const link = cta ? getLink(cta) : {};

  return (
    <SplitThreeImage
      imgLeft={getSanityNextImage(
        imgLeft,
        { small: "800", medium: "800", large: "800" },
        imgLeft.altText,
        "cover",
        priority
      )}
      imgCenter={getSanityNextImage(
        imgCenter,
        { small: "600", medium: "400", large: "400" },
        imgCenter.altText,
        "cover",
        priority
      )}
      imgRight={getSanityNextImage(
        imgRight,
        { small: "800", medium: "800", large: "800" },
        imgRight.altText,
        "cover",
        priority
      )}
      title={title}
      link={link}
    />
  );
}

Hero5.propTypes = {
  imgLeft: PropTypes.object,
  imgCenter: PropTypes.object,
  imgRight: PropTypes.object,
  title: PropTypes.string,
  cta: PropTypes.object,
};

export default Hero5;
