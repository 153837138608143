import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";
import { client } from "@lib/sanity";
import serializers from "./serializers";
import styles from "./styles/serializers.module.css";
const { projectId, dataset } = client.config();

export function SimpleBlockContent(props) {
  const { blocks } = props;

  if (!blocks) {
    console.error("Missing blocks");
    return null;
  }

  return (
    <div className={styles.blockWrapper}>
      <BlockContent
        blocks={blocks}
        serializers={serializers}
        projectId={projectId}
        dataset={dataset}
      />
    </div>
  );
}

SimpleBlockContent.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
};
