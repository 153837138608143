import { BlockRenderer } from "./BlockRenderer";
import Cta from "../Cta";
import styles from "./styles/serializers.module.css";

const List = ({ children, type }) => {
  if (type === "bullet") {
    return <ul className={styles.unorderedList}>{children}</ul>;
  }
  return <ol className={styles.orderedList}>{children}</ol>;
};

const ListItem = ({ children }) => <li className={styles.listItem}>{children}</li>;

const serializers = {
  types: {
    block: BlockRenderer,
  },
  list: List,
  listItem: ListItem,
  marks: {
    internalLink: ({ mark, children }) => {
      const link = {
        title: children.length ? children[0] : children,
        handle: mark.handle,
        slug: mark.slug,
        type: mark.type,
        productGroup: mark.productGroup,
        id: mark.id,
      };
      return <Cta navLink={link} />;
    },
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
};

export default serializers;
