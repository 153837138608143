import PropTypes from "prop-types";
import { PictureInPicture } from "./Hero";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";

function Hero7(props) {
  const { imgBackground, imgForeground, title, cta, priority } = props;

  const link = cta ? getLink(cta) : {};

  return (
    <PictureInPicture
      imgBackground={getSanityNextImage(
        imgBackground,
        { small: "800", medium: "1400", large: "2400" },
        imgBackground.altText,
        "cover",
        priority
      )}
      imgForeground={getSanityNextImage(
        imgForeground,
        { small: "400", medium: "800", large: "1200" },
        imgForeground.altText,
        "cover",
        priority
      )}
      title={title}
      link={link}
    />
  );
}

Hero7.propTypes = {
  imgBackground: PropTypes.object,
  imgForeground: PropTypes.object,
  title: PropTypes.string,
  cta: PropTypes.object,
};

export default Hero7;
