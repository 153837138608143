import { IoIosVolumeOff, IoIosVolumeHigh, IoIosPlay, IoIosPause } from "react-icons/io";

import styles from "./VideoPlayer.module.css";

import { useEffect, useState, useRef } from "react";
import Hls from "hls.js";

export default function VideoPlayer({
  src,
  poster,
  autoPlay = false,
  loop,
  style,
  muted = false,
  controls = false,
}) {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    video.controls = false;
    let hls;

    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      // This will run in safari, where HLS is supported natively
      video.src = src;
    } else if (Hls.isSupported()) {
      // This will run in all other modern browsers
      hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
    } else {
      console.error(
        "This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API"
      );
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src, videoRef]);

  const [mute, setMute] = useState(muted);
  const [playing, setPlaying] = useState(autoPlay);

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setMute(!mute);
  };

  const togglePlay = () => {
    playing ? videoRef.current.pause() : videoRef.current.play();
    setPlaying(!playing);
  };
  return (
    <div style={style} className={styles.videoWrapper}>
      {controls && (
        <div className={styles.videoControls}>
          <button className={styles.videoButton} onClick={togglePlay}>
            {playing ? <IoIosPause size="1.25em" /> : <IoIosPlay size="1.25em" />}
          </button>
          <button className={styles.videoButton} onClick={toggleMute}>
            {mute ? <IoIosVolumeOff size="1.25em" /> : <IoIosVolumeHigh size="1.25em" />}
          </button>
        </div>
      )}
      <video
        onClick={controls ? togglePlay : () => {}}
        ref={videoRef}
        poster={poster}
        preload="metadata"
        controlsList="nodownload nofullscreen noremoteplayback"
        muted={mute}
        playsInline
        autoPlay={autoPlay}
        loop={loop}
        style={{ cursor: controls && "pointer" }}
      />
    </div>
  );
}
