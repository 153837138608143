export const Padding = {
  NONE: "NONE",
  XS: "XS",
  S: "S",
  M: "M",
  L: "L",
  XL: "XL",
};

export const SpreadColors = {
  BEIGE: "#EDEBE7",
  WHITE: "white",
};

export const Height = {
  FULL: "100vh",
  EIGHTY: "80vh",
  AUTO: "auto",
  HALF: "50vh",
};
