import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { SummerSale } from "./Hero";
import { useMobileDetect } from "@hooks/index";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";

function Hero8(props) {
  const { img, mobileImg, title, subTitle, cta, priority } = props;
  const [currentImg, setImg] = useState(img);
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  const link = cta ? getLink(cta) : {};

  useEffect(() => {
    if (isMobile && mobileImg) {
      setImg(mobileImg);
    } else {
      setImg(img);
    }
  }, [isMobile]);

  return (
    <SummerSale title={title} subTitle={subTitle} link={link}>
      {getSanityNextImage(
        currentImg,
        { small: "800", medium: "1400", large: "2400" },
        currentImg.altText,
        "cover",
        priority
      )}
    </SummerSale>
  );
}

Hero8.propTypes = {
  img: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  cta: PropTypes.object,
};

export default Hero8;
