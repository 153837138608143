import PropTypes from "prop-types";
import { Padding, SpreadColors } from "./constants";
import styles from "./styles/item.module.css";

function findElementInArray(array, element) {
  if (array) {
    return array.find((item) => {
      return item === element;
    });
  }
  return false;
}

export function Item({
  children,
  itemsCountLarge = 1,
  itemsCountMedium = 1,
  itemsCountSmall = 1,
  type = "image",
  full = true,
  hasTextOrVideo = false,
  padding = Padding.NONE,
  background = SpreadColors.WHITE,
  hide = [],
}) {
  const hideOnSmall = findElementInArray(hide, "S");
  const hideOnMedium = findElementInArray(hide, "M");
  const hideOnLarge = findElementInArray(hide, "L");

  const gridEnd = Math.max(Math.ceil(4 / itemsCountSmall), 2);
  const gridEndMedium = Math.ceil(6 / itemsCountMedium);
  const gridEndLarge = Math.ceil(12 / itemsCountLarge);

  const itemStyles = `
  ${styles.container}
  ${hideOnSmall ? styles.hideOnSmall : ""}
  ${hideOnMedium ? styles.hideOnMedium : ""}
  ${hideOnLarge ? styles.hideOnLarge : ""}
  ${hasTextOrVideo ? styles.hasTextOrVideo : ""}
  ${type === "spreadItemText" ? styles.text : ""}
  ${type === "richImage" ? styles.img : ""}
  
  ${full ? styles.full : ""}
  ${padding === Padding.NONE ? styles.paddingNone : ""}
  ${padding === Padding.XS ? styles.paddingXS : ""}
  ${padding === Padding.S ? styles.paddingS : ""}
  ${padding === Padding.M ? styles.paddingM : ""}
  ${padding === Padding.L ? styles.paddingL : ""}
  ${padding === Padding.XL ? styles.paddingXL : ""}
  ${background === SpreadColors.BEIGE ? styles.beigeBackground : ""}

  `;

  return (
    <div
      style={{
        "--grid-end": gridEnd,
        "--grid-end-medium": gridEndMedium,
        "--grid-end-large": gridEndLarge,
      }}
      className={itemStyles}
    >
      {children}
    </div>
  );
}

Item.propTypes = {};
