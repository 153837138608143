import PropTypes from "prop-types";
import { Height } from "./constants";
import styles from "./styles/spread.module.css";

export function Grid({ children, full, height = Height.EIGHTY }) {
  const containerStyles = `
    ${styles.container}
    ${full ? styles.full : ""}
    ${height === Height.FULL ? styles.fullHeight : ""}
    ${height === Height.EIGHTY ? styles.eightyHeight : ""}
    ${height === Height.AUTO ? styles.autoHeight : ""}
    ${height === Height.HALF ? styles.halfHeight : ""}
    `;

  return <article className={containerStyles}>{children}</article>;
}

Grid.propTypes = {};
