import PropTypes from "prop-types";
import { SingleImage } from "./Hero";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";
function Hero1(props) {
  const { img, title, cta, priority } = props;

  const link = cta ? getLink(cta) : {};

  return (
    <SingleImage title={title} link={link}>
      {getSanityNextImage(
        img,
        { small: "800", medium: "1400", large: "2400" },
        img.altText,
        "cover",
        priority
      )}
    </SingleImage>
  );
}

Hero1.propTypes = {
  img: PropTypes.object,
  title: PropTypes.string,
  cta: PropTypes.object,
};

export default Hero1;
