import PropTypes from "prop-types";
import { SplitSingleImage } from "./Hero";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";

function Hero4(props) {
  const { img, title, cta, priority } = props;

  const link = cta ? getLink(cta) : {};

  return (
    <SplitSingleImage title={title} link={link}>
      {getSanityNextImage(
        img,
        { small: "800", medium: "600", large: "1200" },
        img.altText,
        "cover",
        priority
      )}
    </SplitSingleImage>
  );
}

Hero4.propTypes = {
  img: PropTypes.object,
  title: PropTypes.string,
  cta: PropTypes.object,
};

export default Hero4;
