import PropTypes from "prop-types";
import { TwoColumn } from "./Hero";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";

function Hero6(props) {
  const { img, ctas } = props;

  const links = ctas ? ctas.map((cta) => getLink(cta)) : [{}, {}];

  return (
    <TwoColumn linkLeft={links[0]} linkRight={links[1]}>
      {getSanityNextImage(img, { small: "800", medium: "1400", large: "2400" }, img.altText)}
    </TwoColumn>
  );
}

Hero6.propTypes = {
  img: PropTypes.object,
  ctas: PropTypes.arrayOf(PropTypes.object),
};

export default Hero6;
