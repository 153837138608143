import PropTypes from "prop-types";
import { SimpleBlockContent } from "@components/SimpleBlockContent";
import styles from "./styles/text.module.css";

function TextSection(props) {
  const { text } = props;

  return <div className={styles.box}>{text && <SimpleBlockContent blocks={text} />}</div>;
}

TextSection.propTypes = {
  text: PropTypes.arrayOf(PropTypes.object),
};

export default TextSection;
