import PropTypes from "prop-types";
import { Product } from "../ProductCard";
import { Carousel } from "@components/Carousel";
import { removeGender } from "@lib/formatString";
import styles from "./styles/products.module.css";

const addInfoToProductObject = (products, allProducts) => {
  const newProductList = products
    ?.map((product) => {
      const p = allProducts.find((p) => p.handle === product.store.slug.current);
      return p;
    })
    .filter((p) => p !== null && p !== undefined);

  return newProductList;
};

function showProductCards(newProductList) {
  return newProductList?.map((product, index) => (
    <Product product={product} listPosition={index} key={`${product.id}-${index}`} />
  ));
}
function ProductList(props) {
  const { productsV2, title, style, allProducts = [] } = props;
  const newProductList = addInfoToProductObject(productsV2, allProducts);

  if (style === "carousel" && productsV2.length > 3) {
    return (
      <section>
        <header className={styles.header}>
          <h2>{removeGender(title)}</h2>
        </header>
        <Carousel>{showProductCards(newProductList)}</Carousel>
      </section>
    );
  }

  return (
    <section>
      <header className={styles.header}>
        <h2>{removeGender(title)}</h2>
      </header>
      <div className={styles.wrapper}>{showProductCards(newProductList)}</div>
    </section>
  );
}

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
};

export default ProductList;
